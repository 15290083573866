@font-face {
   font-family: "Roboto";
   src: url("fonts/Roboto-Regular.ttf") format("truetype");
}

body {
   font-family: "Roboto", sans-serif;
   font-style: normal;
   font-weight: normal;
}

@media (min-width: 480px) {
   h1 {
       font-size: 1.5rem;
       font-weight: 400;
   }
   h2 {
       font-size: 1.25rem;;
       font-weight: 200;
   }
}

@media (min-width: 768px) { 
   h1 {
       font-size: 1.75rem;
       font-weight: 400;
   }
   h2 {
       font-size: 1.75rem;;
       font-weight: 200;
   }
}