.k-link {
  background-color: #426ac9;
  color: white; 
}
.k-drawer-item:hover {
  background-color: #2e498b;
  color: white;
}
.k-drawer-item.k-selected {
  background-color: #426ac9;
  color: white;
}
.k-drawer-item.k-selected:hover {
  background-color: #2e498b;
  color: white;
}
.k-pager-numbers.k-link.k-selected {
  color: #0817ed;
  background-color: rgba(42, 76, 207, 0.2);
}
.k-tabstrip-items-wrapper .k-item {
  color: white;
}
