@import "./styles/typography.scss";

span.k-textbox {
  width: 100%;
}

.logo-wrapper{
  height: 370px;
  width: 270px;

}

.logo {
  height: 186px;
  width: 270px;
}
.banner {
  height: 68px;
  color:#2727BE;
  font-weight: 300;
  font-size: 56px;
  display: flex;
  align-items: center;
  justify-content:center;
  margin-top:32px;
}
.account {
  position: relative;
  height: 20px;
  left: 31.5px;
  margin:32px 0px 32px 0px;
  font-size: 14px;

}

.account>a {
  margin-left: 16px;
  color:#0058E9;
}

.continue-with-wrapper {
  display: inline-flex;
  height: 20px;
  width: 270px;
  margin-bottom:16px;
  margin-top: 16px;

}
.continue-with-wrapper>span{
  position: static;
  color: #8F8F8F;       
  font-size: 14px;
  line-height: 20px;

}

hr {
  width:75px;
  height:0px;
  border: 1px solid #8F8F8F;
  margin-top: 9px;
}

.sign-in-page {
  position: relative;
  height: 100vh;
  display: flex;
  background: linear-gradient(16.66deg, #070B46 45.27%, #2D2DD3 100%);
}

.sign-in-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-width: 560px;
  width: 39%;
  z-index: 1;
  background: #FFFFFF;
}

.login {
  height: 202px;
  width: 270px;
  justify-content: space-between;
  display: flex;
  flex-flow: column;

}
div.k-form-field:nth-child(3) {
  display: flex;
}
.RememberMe-form-label {
  order: 1;
  padding-left: 0.5em;
}
.k-form-field-wrap {
  position: relative;
}

.sign-button {
  height: 30px;
  width: 270px;
  background:#FF6358;
  color: #FFFFFF;
}

.sign-button:hover  {
  background:#FF6358;
}

.k-button-text {
  text-decoration: none;
}

.social-wrapper {
  display: flex;
  width:194px;
  height:30px; 
  top:755px;
  left:183px; 
  
}

.facebook {
  height: 30px;
  width: 30px;
  left: 20px;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  background: rgba(66, 66, 66, 0.04);
  border-radius: 2px;

}

.twitter {
  height: 30px;
  width: 30px;
  left: 20px;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 35px;
  background: rgba(66, 66, 66, 0.04);
  border-radius: 2px;

}

.reddit{
  height: 30px;
  width: 30px;
  left: 20px;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 35px;
  background: rgba(66, 66, 66, 0.04);
  border-radius: 2px;

}
.image-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 61%;
  height: 60%;
  z-index: 9000;
}

.second-image-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 61%;
  height: 60%;
}

.image-wrapper img {
  z-index: 9000;
  margin: auto;
  display: block;
}

.frame-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 61%;
  max-width: 542px;
  margin: 0 auto;

}

.frame-wrapper h2 {
  font-size:48px;
  color:#FFFFFF;
  font-weight: bold;
  text-align: center;
  line-height: 1.4;
}

.frame-wrapper h4 {
  font-size: 19px;
  text-align:center;
  color:#FFFFFF;
  font-weight: normal;
  line-height:1.3;
}

.text-wrapper {
 margin-top: 5vh;
}

.dashboard-button {
  text-decoration: none;
}

.custom-toolbar {
  padding: 6px 25px 0px 24px;
  height: 48px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.06);
  height: 40px;

  button {
    border-color: transparent;
    background-color: white;
    border: 0px;
 }

 button:hover {
  border-color: transparent;
  background-color: white;
  border: 0px;
 }
}


.overview-button {
  height: 30px;
  width: 30px;
  color: #2D73F5;

}


.drawer {
  border-right-width: 1px;
  height: 100%;
}

.overview {
 color: #2D73F5;
 margin-left: 16px;
 font-family: 'Roboto';
 font-style: normal;
 font-weight: 400;
 font-size: 18px;
}

.right-widget {
  float: right;
  display: flex;
}

.alert-container {
  margin-right: 50px;
   a {
    color: #424242 !important;
   }
}

.k-avatar {
  height: 64px;
  width: 64px;
}

.p-2 {
  width: 398px;
}

.message-container {
  margin-top: 16px;
  margin-left: 8px;
   
  h1, h2 {
    width: 300px;
   }
}

.popup-content {
  margin-top: 10px;
}

.mark-button {
  height: 30px;
  width: 165px;
  float: right;
  border: solid 1px #FF6358;
  color: #FF6358;
  background-color: white;
  right: 10px;
}

.message-container:nth-child(1) {
  background-color: lightyellow;
}

.k-drawer-content {
  margin-top: -210px;
  height: 100%;
  width: 100%;
  background-color: #fafafa;
}

.user-container {
  width: 240px;
  padding-top: 32px;
  max-width: 100%;
  border-width: 0;
  border-right: solid 1px rgba(0, 0, 0, 0.08);;

  box-sizing: border-box;
  outline: 0;
  text-align: center;
   img {
    height: 64px;
    width: 64px;
   }

   h1 {
    font-size: 14px;
    margin-top: 13px;
    color: #424242;
    font-family: 'Roboto';
    font-weight: 700;
   }

   .user-email {
    font-size: 12px;
    margin-top: 12px;
    text-decoration: underline;
    font-weight: 400;
   }

   .user-button {
    font-size: 12px;
    width: 71px;
    height: 30px;
    margin-top: 11px;
    background: rgba(66, 66, 66, 0.16);
    border-radius: 2px;
    margin: 12px 0 9px 0;
   }
}

a {
  text-decoration: none !important;
}

my-app { padding: 0 !important; }

.k-drawer-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.k-drawer {
  height: 100%;
  max-width: 100%;
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.42857143;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  transition: all .3s ease-in-out;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.custom-toolbar {
  padding: 6px 25px 0px 24px;
  position: relative;
  z-index: 300;
  height: 48px;
  background: #FFFFFF;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.06);
  width: 100%;
  height: 40px;
}


.k-notification-container {
  .k-widget {
    padding-top: 0px;
  }
}

.k-popup {
  .k-widget {
    padding-top: 0px;
  }
}

.greeting {
  font-weight: 300;
  font-size: 28px;
  padding: 24px 0px 8px 17px;
  background: #fafafa;
}

.dashboard-card-content-number {

font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 84px;
line-height: 80px;
text-align: center;

}

.dashboard-card-content {
  text-align: center;
}

.green {
  color: #37B400;
}

.red {
  color: #F31700;
}

.orange {
  color: #FFC000;
}

.footer, .k-card-title 
  {
  color: #666666;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.k-card-header {
  border-color: transparent;
}

.gauge-div {
  margin-top: -60px;
  text-align: center;
}

.gauge-footer {
  color: #666666;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 42px;
}

.card-buttons {
  display: flex;
  justify-content: space-between;

  margin-top: 20px;
  margin-right: 34px;
   Button {
    width: 106px;
    height: 30px;
   }

   p {
    margin-left: 15px;
    font-size: 20px;
   }

   .k-daterange-picker, .k-daterangepicker {
    margin-top: -20px;

    label {
       display: none;
    }
}
}

.chart-container {
  margin-left: 13px;
  margin-right: 13px;
}

.k-rating-item.k-selected {
  color: #FFA600;
}  

.person-name {
  margin-left: 10px;
}

.grid-container {
  margin-top: 0px;
  height: 500px;
  .export-buttons-container {
    margin-left: 10px;
  }
}

.circular-gauge-container {
  display: flex;
}

.values-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.progress-bar-container {
  display: flex;
  padding-bottom: 10px;
  color: black;
  font-weight: 700;
  margin-bottom: -20px;
  font-size: 12px;
   span {
    padding: 5px;
   }
}

.value-span {
  margin-top: -7px;
  margin-left: 21.25px;
}

.country-name-container {
  margin-top: -7px;
  margin-right: 11px;
}

.country-name {
  font-size: 12px;
  width: 250px;
}



.listbox-card-container {
   margin-top: 100px;
  .k-listview-content {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .k-icon.k-i-preview {
    z-index: 10;
    bottom: 0px;
    position: inherit;
    right: -1px;
    width: 24px;
    height: 24px;
    color: gray;
  }
  
}

.input-container {
  text-align: center;
   margin-top: 50px;
   input {
    width: 500px;
   }
}

.footer-buttons-container {
  display: flex;
  flex-direction: column;
  margin-left: 75px;
  margin-top: -7px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.bottom-side {
  margin-top: 90px;
}

.top-side {
  margin-top: 820px;
}

.switch-parent, .f-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}

.date-container {
  text-align: center;
}

.weather-data {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  width: 100%;
  text-align: center;
}

.weather-data-container  {
  margin-top: 16px;
  padding: 8px 0px 0px;
  gap: 18px;
}

.icon-header-container {
  display: flex;
  justify-content: space-between;
    p {
    color: #666666;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    }

    a {
      font-size: 20px;
      color: #0058E9 !important;
    }

    .globe-span {
      padding-right: 15px;
      margin-top: -4px;
    }
}

.triple-inputs {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.switch-options {
  .switch-parent {
    margin-top: 0px;
    height: 10px;
  }
}

.k-disabled {
  display: none;
}

.card-buttons-parent {
   display: flex;
   justify-content: end;
   margin-top: -60px;
   margin-right: 0px;
}

.gray-text {
  color: #8F8F8F
}

.about {
  margin-left: 35px;
  margin-top: 35px;
  margin-bottom: 35px;
}

.overflow {
  overflow-y: scroll;
}

@media (max-width: 800px) {
  .image-wrapper {
    display: none;
  }
}

.password-icon {
  position: absolute;
  margin-left: 245px;
  margin-top: 28px;
  color: gray;
}

.switch {
  margin-left: 20px;
}